<template>
<div class="background">
    <!-- <div class="background" @click="clickBack()"> -->

    <v-dialog max-width="50%" v-model="dialogNew" v-if="dialogNew" persistent>
        <acces-control-new @close="closeModal()" :person="person"></acces-control-new>
    </v-dialog>

    <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
            <atc-requirement-edit :value="RequirementEdit.ReqID" @close="dialog = false" :itemOC="selectedOC[0]" :visible="true"></atc-requirement-edit>
        </v-dialog>
    </div>

    <div v-if="dialogGPS">

        <v-dialog v-model="dialogGPS" scrollable max-width="100%" fullscreen>
            
            <v-card>
                <v-card-title>Ubicación Geografica<br>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text >
                    <template>
                        <v-card outlined>
                            <v-row>
                                <!-- <v-col cols="12" class="ml-3">
                                    <v-btn color="" elevation="1" small @click="VisionText('terrain')">Map</v-btn>
                                    <v-divider vertical class="ml-3"></v-divider>
                                    <v-btn color="" elevation="1" small @click="VisionText('satellite')" class="ml-2">Satellite</v-btn>
                                </v-col> -->
                                <v-divider></v-divider>
                                <v-col cols="12" class="ml-3 mt-0 mb-2" style="margin-bottom:-14px">
                                    <h3>Ubicacion GPS:</h3> <a target="_blank" :href="itemGPS.Uri">{{itemGPS.Uri}}</a>
                                </v-col>

                                <!-- https://www.google.com/maps/place/5%C2%B010'25.1%22S+80%C2%B036'26.6%22W/@-5.1736389,-80.6073889,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-5.1736389!4d-80.6073889 -->
                                <!-- https://www.google.com/maps/place/5%C2%B010'25.1%22S+80%C2%B036'26.6%22W/@-5.1736297,-80.6095757,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-5.173635!4d-80.607387!5m1!1e4?hl=es -->
                                <!-- <v-divider class="mt-3 mb-6"></v-divider> -->
                            </v-row>
                        </v-card>

                        <c-google-maps ref="map" :vision="vision" :latitude=itemGPS.Latitude :longitude=itemGPS.Longitude :title="itemGPS.Lote + ' - ' + itemGPS.Proyecto"></c-google-maps>

                    </template>

                    

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialogGPS = false">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--  <v-dialog class="elevation-0" v-model="dialogGPS" width="100%" persistent transition="dialog-bottom-transition">
            <s-toolbar close @close="dialogGPS=false"></s-toolbar>

        </v-dialog> -->
    </div>

    <div>
        <v-text-field class="inputControl" number color="white" autofocus ref="textcontrol" @keyup.enter="search();" dark maxlength=15 v-model="document" hide-details="" outlined dense block></v-text-field>

        <v-col style="padding-top: 0px; padding-bottom: 0px">

            <v-col style="padding: 0px">
                <v-card outlined min-height="240" style="background:rgba(255,255,255,0.9); padding:0px;margin:0px;">
                    <v-card-title style="background:#5175AF;">
                        <v-row>
                            <v-col class='d-flex justify-center '>
                                <h1 style="font-size: 24px !important ;color:white;height:15px !important;" class=" d-flex d-sm-none text-center">
                                    {{ 'IMP' }}
                                </h1>
                                <h1 style="font-size: 24px !important ;color:white;height:15px !important;" class=" d-none d-sm-flex text-center">
                                    {{ 'INMOBILIARIA MIRAFLORES PERÚ SAC' }}
                                    <v-btn x-small elevation="0" @click="fullScreen()" class="ml-4" style="text-transform:capitalize">Full</v-btn>
                                    <v-btn x-small elevation="0" @click="cancel()" class="ml-2" style="text-transform:capitalize">Inicio</v-btn>

                                </h1>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-col>
                        <v-row>
                            <v-col cols="12" lg="8" md="8">
                                <v-col class="s-color-title mt-10" style="" cols="12">
                                    <h4 v-if="person.PrsDocumentNumber !== ''" style="font-size: 12px !important; line-height: 1.1" class="s-color-title d-none d-sm-flex text-center">
                                        <v-row class="mb-0 ml-0" style="text-decoration:;margin-top:-50px">
                                            <v-img src="../../../assets/img/574268.png" max-height="30" max-width="30"></v-img>
                                            <v-btn text icon x-small elevation="0" style="margin-left:30px;font-weight: 700;text-transform: none;" @click="openVisi()">Agregar Visita</v-btn>
                                            <!-- <v-btn text icon x-small elevation="0" style="margin-left:70px;font-weight: 700;text-transform: none;" @click="viewComprobantes()">Comprobantes</v-btn> -->
                                        </v-row>
                                    </h4>
                                    <h1 v-if="person.NtpName" style="font-size: 30px !important; line-height: 1.1;margin-top:-30px" class="s-color-title d-none d-sm-flex text-center">
                                        <v-row justify="center">
                                            {{
                        person.NtpName
                      }}</v-row>
                                    </h1>
                                    <h1 v-if="person.NtpName" style="font-size: 32px !important" class="d-flex d-sm-none s-color-title text-center">
                                        {{
                      person.NtpName
                    }}
                                    </h1>

                                    <h1 v-else style="font-size: 30px !important" class="s-color-title text-center">
                                        CONTROL DE VISITAS CLIENTE
                                    </h1>

                                    <h1 style="font-size: 28px !important" class="d-none d-sm-flex s-color-title text-center">
                                        <v-col class="d-flex justify-center" style="padding:0px">{{
                      person.PrsDocumentNumber
                    }}</v-col>
                                    </h1>
                                    <h1 style="font-size: 24px !important" class="text-center d-sm-none s-color-title">
                                        {{ person.PrsDocumentNumber }}
                                    </h1>
                                </v-col>
                                <v-divider></v-divider>

                                <!-- <v-col class="s-color-title mt-3" style="" cols="12">
                                    <h4 style="font-size: 12px !important; line-height: 1.1" class="s-color-title d-none d-sm-flex text-center">
                                        <v-row class="mb-3" style="text-decoration: underline;">Conyuge o Copropietario</v-row>
                                    </h4>
                                    <h1 v-if="person.NtpName" style="font-size: 30px !important; line-height: 1.1" class="s-color-title d-none d-sm-flex text-center">
                                        <v-row justify="center">
                                            {{
                        person.NtpName
                      }}</v-row>
                                    </h1>
                                    <h1 v-if="person.NtpName" style="font-size: 32px !important" class="d-flex d-sm-none s-color-title text-center">
                                        {{
                      person.NtpName
                    }}
                                    </h1>
                                    <h1 style="font-size: 32px !important" class="d-none d-sm-flex s-color-title text-center">
                                        <v-col class="d-flex justify-center" style="padding:0px">{{
                      person.PrsDocumentNumber
                    }}</v-col>
                                    </h1>
                                    <h1 style="font-size: 24px !important" class="text-center d-sm-none s-color-title">
                                        {{ person.PrsDocumentNumber }}
                                    </h1>
                                </v-col> -->
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col cols="12" lg="4" md="4">
                                <v-row>

                                    <v-col style="text-align: -webkit-center;" cols="4" v-if="itemsCommitmentSlope.length >0">
                                        <h3>Cobranzas</h3>
                                        <v-divider horizontal></v-divider>
                                        <v-img @click="show = !show" src="../../../assets/img/triste.png" max-height="100" max-width="100"></v-img>
                                        <v-divider horizontal></v-divider>
                                        <div><b>Tiene <a @click="show = !show">
                                                    <h2>{{itemsCommitmentSlope.length}}</h2>
                                                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                                </a>Compromiso de Pago</b></div>
                                        <v-divider horizontal></v-divider>
                                        <v-card dense class="mt-3">
                                            <v-expand-transition>
                                                <div v-show="show">
                                                    <ul>

                                                        <li style="text-align: left;" v-for="(slope, index) in itemsCommitmentSlope">
                                                            <b>{{slope.CobDateCommitment}}</b> || {{slope.CobDescription}} - <br><b>{{slope.CobUserName}}</b>

                                                        </li>

                                                    </ul>
                                                </div>
                                            </v-expand-transition>

                                        </v-card>
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col style="text-align: -webkit-center;" cols="4" v-if="itemsCommitmentSlope.length ==0 && person.PrsDocumentNumber !== ''">
                                        <h3>Cobranzas</h3>
                                        <v-img src="../../../assets/img/feliz.png" max-height="100" max-width="100"></v-img>
                                        <div><b>No tiene Compromiso de Pago</b></div>
                                    </v-col>
                                    <!-- 
                                    <v-divider vertical></v-divider>
                                    <v-col>
                                        <v-img src="../../../assets/img/enojado.png" max-height="100" max-width="100"></v-img>
                                    </v-col> -->
                                    <v-divider vertical></v-divider>
                                    <v-col style="text-align: -webkit-center;" cols="4" v-if="itemsRequirement.length >0">
                                        <h3>AA.CC</h3>
                                        <v-divider horizontal></v-divider>
                                        <v-img @click="show = !show" src="../../../assets/img/triste.png" max-height="100" max-width="100"></v-img>
                                        <v-divider horizontal></v-divider>
                                        <div><b>Tiene <a @click="show = !show">
                                                    <h2>{{itemsRequirement.length}}</h2>
                                                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                                </a>Solicitud(es) en Proceso</b></div>
                                        <v-divider horizontal></v-divider>
                                        <v-card dense class="mt-3">
                                            <v-expand-transition>
                                                <div v-show="show">
                                                    <!-- <ul> -->

                                                    <div style="text-align: left;" v-for="(requirement, index) in itemsRequirement">
                                                        <!-- {{requirement}} -->
                                                        <v-divider></v-divider>
                                                        <v-icon @click=showForm(requirement) color="success" :size="10">
                                                            mdi-checkbox-blank-circle
                                                        </v-icon>Click<br>

                                                        Solicitud Nro:
                                                        <a @click=showForm(requirement)><b style="text-decoration-line: underline;">{{requirement.ReqDocumentNumber}}</b></a><br>
                                                        Tipo: <b>{{requirement.TrsName}}</b><br>
                                                        Estado: <b>{{requirement.AlvProcessName}}</b><br>
                                                        Creado Por: <b>{{requirement.UsrName}}</b>
                                                        <br>
                                                        <v-divider></v-divider>

                                                    </div>

                                                    <!-- </ul> -->
                                                </div>
                                            </v-expand-transition>

                                        </v-card>
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col style="text-align: -webkit-center;" cols="4" v-if="itemsRequirement.length ==0 && person.PrsDocumentNumber !== ''">
                                        <h3>AA.CC</h3>
                                        <v-img src="../../../assets/img/feliz.png" max-height="100" max-width="100"></v-img>
                                        <div><b>Sin Solicitudes en Proceso</b></div>
                                    </v-col>

                                </v-row>

                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>

                <v-card outlined>
                    <v-col>
                        <v-row>

                            <v-col class="s-color-title mt-0" style="" cols="12" lg="6" md="6">
                                <h4 class="ml-0">
                                    <v-chip x-small color="">Historia de Visita</v-chip>
                                </h4>

                                <v-card outlined>

                                    <v-data-table disable-sort height="200" dense hide-default-footer :headers="headersHV" :items="itemsVisita" v-model="selectedVisita" @click:row="rowClickVisita" item-key="VstID"></v-data-table>
                                </v-card>

                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="s-color-title mt-0" style="" cols="12" lg="6" md="6">
                                <h4 class="ml-0">
                                    <v-chip x-small color="">Detalle de Lote</v-chip>
                                    <v-btn v-if="selectedOC.length > 0" class="capitalize save" text x-small @click="showForm()" elevation="0">
                                        <v-icon color="info" style="font-size:18px; border-radius: 20px" class="mr-1">mdi-plus-circle-outline</v-icon>
                                    </v-btn>
                                </h4>

                                <v-card outlined>
                                    <v-data-table disable-sort height="200" dense hide-default-footer :headers="headersOC" :items="itemsOC" v-model="selectedOC" @click:row="rowClickOC" item-key="CodSales">
                                        <template v-slot:item.UbicationGPS="{ item }">
                                            <v-icon @click="openGPS(item.UbicationGPS)" color="info" style="font-size:18px; border-radius: 20px" class="mr-1">fa-solid fa-location-arrow</v-icon>
                                        </template>

                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>

                <v-card outlined></v-card>

                <v-card outlined>
                    <v-col>
                        <v-row>

                            <v-col class="s-color-title mt-0" style="" cols="12" lg="12" md="12">
                                <!--  <v-container> -->
                                <v-card outlined>
                                    <v-tabs v-model="currentItem">
                                        <v-tab href="#Cobranzas"> Cobranzas</v-tab>
                                        <v-tab href="#AACC"> Atencion al Cliente</v-tab>
                                        <v-tab href="#Legal"> Legal</v-tab>
                                    </v-tabs>

                                    <v-tabs-items v-model="currentItem">
                                        <v-tab-item :value="'Cobranzas'">
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-card outlined>
                                                            <v-data-table height="200" dense hide-default-footer :headers="headersCobranza" :items="itemsCobranza"></v-data-table>
                                                        </v-card>

                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item :value="'AACC'">
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-card outlined>
                                                            <v-data-table height="200" dense hide-default-footer :headers="config.headers" :items="itemsRequirement"></v-data-table>
                                                            <!-- <s-crud no-search noFull height="200" ref="grid" :filter="filter" :config="config">
                                                            </s-crud> -->

                                                        </v-card>

                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card>
                                <!-- </v-container> -->
                            </v-col>

                        </v-row>
                    </v-col>
                </v-card>
            </v-col>

        </v-col>
    </div>
</div>
</template>

<script>
import ControlAccessService from "@/services/Management/ControlAccess";
import _sControl from '@/services/CustomerSupport/AtcCustomerControlService'
import _sSunarp from "@/services/General/GenSunarpService";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import _sRequirement from "@/services/CustomerSupport/AtcRequirementService";

import AtcRequirementEdit from "@/views/CustomerSupport/Requirement/AtcRequirementEdit";
/* 

import _sOC from "@/services/CustomerSupport/AtcOperationCommercial"; */

//Components
import AccesControlNew from "./AccesControlNew.vue";

export default {
    components: {
        AccesControlNew,
        AtcRequirementEdit
    },
    props: {},

    data() {
        return {
            RequirementEdit: {},
            dialog: false,
            show: false,
            currentItem: "tab-Funciones",
            dialogNew: false,
            document: "",
            timeRefresh: 0,
            person: {
                NtpFullName: "CONTROL DE VISITAS CLIENTE",
                PstName: "",
                PrsDocumentNumber: "",
                color: "white",
            },
            processing: false,
            messageProcessing: "",
            itemsOC: [],
            itemsVisita: [],
            selectedOC: [],
            selectedVisita: [],
            itemGPS: {},
            dialogGPS: false,
            vision: "terrain",
            headersOC: [{
                    text: "Cod. Venta",
                    value: "CodSales"
                },
                {
                    text: "Proyecto",
                    value: "ProjectName"
                },
                {
                    text: "Etapa",
                    value: "Stage"
                },
                {
                    text: "Nro. Lote",
                    value: "NroLot"
                },
                {
                    text: "Estado",
                    value: "EstateOC"
                },
                {
                    text: "Doc. Identidad",
                    value: "DocCustomer"
                },
                {
                    text: "Ubicación",
                    value: "UbicationGPS"
                },
                /* {
                    text: "Cliente",
                    value: "CustomerName"
                },
                {
                    text: "Direccion",
                    value: "CustomerAddress"
                },
                {
                    text: "Celular",
                    value: "CustomerCel"
                }, */
            ],
            headersHV: [{
                    text: "Fecha",
                    value: "VstDate"
                },
                {
                    text: "Hora",
                    value: "VstHour"
                },
                {
                    text: "Descripcion",
                    value: "VstDescription"
                },
                {
                    text: "Responsable",
                    value: "TypeResponsibleName"
                },
                {
                    text: "Motivo",
                    value: "TypeVisitName"
                },
                {
                    text: "Area",
                    value: "AreName"
                }
            ],

            headersCobranza: [
                /*  {
                     text: "Fecha",
                     value: "CobDateRegister",
                     width: 120
                 }, */
                {
                    text: "Fec. Comp.",
                    value: "CobDateCommitment",
                    width: 120
                },
                {
                    text: "Descripciòn",
                    value: "CobDescription",
                    width: 400

                },
                {
                    text: "Codigo V.",
                    value: "CobCodSales",
                    width: 100
                },
                {
                    text: "Lote",
                    value: "CobNroLot",
                    width: 80
                },
                {
                    text: "Proyecto",
                    value: "CobProjectName",
                    width: 250
                },
                {
                    text: "Modo",
                    value: "TypeModName",
                    width: 100
                },
                {
                    text: "Gestiòn",
                    value: "TypeGestionName",
                    width: 200
                },

            ],

            itemsCobranza: [],
            itemsCommitmentSlope: [],

            //Atencion Al Cliente
            itemsRequirement: [],
            filter: {
                CodSales: null,
                BeginDate: '1900-01-01',
                EndDate: this.$fun.getDate()
            },
            config: {
                model: {
                    ReqDocumentNumber: "ID",
                },
                service: _sRequirement,
                headers: [{
                        text: "ID",
                        value: "ReqID",
                        sortable: false,
                        width: 40
                    },

                    {
                        text: "N° Solicitud",
                        value: "ReqDocumentNumber",
                        sortable: false,
                        width: 100,
                    },
                    {
                        text: "Codigo Venta",
                        value: "CodSales",
                        sortable: false,
                        width: 100,
                    },

                    {
                        text: "Tipo Solicitud",
                        value: "TrsName",
                        sortable: false,
                        width: 100,
                    },
                    {
                        text: "Medio",
                        value: "TypeMedioName",
                        sortable: false,
                        width: 100,
                    },

                    {
                        text: "Solicitante",
                        value: "UsrName",
                        sortable: false,
                        width: 200,

                    },
                    {
                        text: "Estado",
                        value: "ReqStatusName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Estado Proceso",
                        value: "AlvProcessName",
                        sortable: false,
                        width: 150,
                        align: "center",
                    },

                    {
                        text: "Prioridad",
                        value: "TypePriorityName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Fecha Inicio",
                        value: "ReqDate",
                        sortable: false,
                        width: 110
                    },
                    {
                        text: "Fecha Fin",
                        value: "SecUpdate",
                        sortable: false,
                        width: 140
                    },
                    {
                        text: "Tiemp. Proceso",
                        value: "ReqDay",
                        sortable: false,
                        width: 40,
                        align: "center",
                    },

                ],
            },
        }
    },

    created() {
        this.start();
        let timerId = setInterval(() => {
            this.timeRefresh = this.timeRefresh + 1000;
            if (this.timeRefresh >= localStorage.getItem("TimeToken") / 2) {
                $("body").trigger("mousemove");
                clearInterval(timerId);
            }
        }, 1000);

        console.log("TIEMPO TOKEN", this.timeRefresh)
    },

    mounted() {
        /* this.$refs.map.$refs.mapRef.$mapObject.forEach(element => {
           console.log(element)
        }); */
        /* console.log(this.$refs.map.$refs) */
        /*  this.$refs.map.$refs.mapRef.$mapPromise.then((map) => {
            console.log(map.mapTypeId)
            console.log(map)
        }) */
    },

    watch: {
        itemsCobranza() {
            if (this.itemsCobranza.length > 0) {
                this.itemsCommitmentSlope = this.itemsCobranza.filter(x => x.CobDateCommitment >= this.$fun.getDate())
                console.log("compromisos", this.itemsCommitmentSlope)

            }
        },

        dialog() {
            if (!this.dialog) {
                this.document = this.person.PrsDocumentNumber
                this.search()
            }
        }

        /* selectedOC() {
            this.filter.CodSales = this.selectedOC[0].CodSales
        } */
    },

    methods: {

        showForm(item) {

            console.log(item)

            if (item == undefined) {

                this.RequirementEdit.ReqID = null;
                this.dialog = true
            } else {
                this.RequirementEdit = item;
                this.dialog = true
            }
        },

        closeModal() {
            this.dialogNew = false
            this.refresh()
        },

        refresh() {
            this.listVisit()
        },

        openVisi() {
            this.dialogNew = true
        },
        clickBack() {
            this.$refs.textcontrol.focus();
        },

        start() {

            this.$nextTick().then(() => {
                this.$refs.textcontrol.focus();
            });
        },

        fullScreen() {
            this.$fun.fullScreen();
        },

        cancel() {
            /* this.$router.go(-1); */
            this.$router.replace("/");
        },

        removeNonNumeric(testString) {
            var NumericString = testString.replace(/[^0-9]/g, '');
            return NumericString;
        },

        save(controlAccess, name) {
            ControlAccessService.saveaccesscustomer(
                this.document,
                name == null ? "" : name,
                this.$fun.getUserID(),
                controlAccess
            ).then(r => {

            })
        },
        search() {

            this.itemsOC = []
            this.itemsVisita = []
            this.itemsCobranza = []
            this.itemsCommitmentSlope = []
            this.itemsRequirement = []

            if (this.document.length > 3) {
                this.document = this.removeNonNumeric(this.document)
                _sControl.byDocument({
                    PrsDocumentNumber: this.document
                }, this.$fun.getUserID()).then(respuesta => {
                    console.log("data", respuesta.data)
                    if (respuesta.data.xFlag == 1) {
                        _sSunarp.searchsunarp(this.document, this.$fun.getUserID()).then(resp => {
                                if (resp.status == 200) {
                                    this.save(1, resp.data == null ? "" : resp.data.SunName)
                                    this.person.NtpName = resp.data.SunName
                                    this.person.PrsDocumentNumber = resp.data.SunNumberDocument
                                    this.document = "";
                                    this.clickBack();
                                }
                            },
                            (e) => {
                                console.log("data sunat", e.status)
                                this.save(0, e.status == null ? "" : resp.data.SunName)
                                this.person.NtpName = "No se encontraron datos"
                                this.person.PrsDocumentNumber = this.document
                                this.document = "";
                                this.clickBack();
                            });
                    } else {
                        this.save(1, respuesta.data == null ? "" : respuesta.data.NtpFullName)
                        this.person.NtpName = respuesta.data.NtpFullName
                        this.person.PrsDocumentNumber = respuesta.data.PrsDocumentNumber
                        this.document = "";
                        this.clickBack();
                    }

                    this.itemsOC = respuesta.data.listOC
                    this.itemsVisita = respuesta.data.listVisit
                    this.itemsCobranza = respuesta.data.listCobranzas
                    this.itemsRequirement = respuesta.data.listRequirement
                    console.log("this.itemsRequirement", this.itemsRequirement)

                    if (this.itemsVisita.length > 0) {
                        this.itemsVisita.forEach(element => {
                            element.VstDate = element.SecCreate == null ? "" : this.$moment(element.SecCreate).format(this.$const.FormatDateDB);
                            element.VstHour = element.SecCreate == null ? "" : this.$moment(element.SecCreate).format(this.$const.FormatTimeDB);
                        });
                    }

                    if (this.itemsCobranza.length > 0) {
                        this.itemsCobranza.forEach(element => {
                            element.CobDateRegister = element.CobDateRegister == null ? "" : this.$moment(element.CobDateRegister).format(this.$const.FormatDateTimeDB);
                            element.CobDateCommitment = element.CobDateCommitment == null ? "" : this.$moment(element.CobDateCommitment).format(this.$const.FormatDateDB);
                        });
                    }
                })
            }

        },

        openGPS(item) {

            if (item !== null) {
                this.itemGPS = item
                console.log("itemGPS", this.itemGPS)
                this.dialogGPS = true
            }else{
                this.$fun.alert("Ni tiene coordenadas registradas", "warning")
            }
        },

        VisionText(item) {
            if (item !== null) {
                this.vision = item
            }
        },

        /* access() {
            if (this.document.length > 3) {

                this.messageProcessing = ""
                this.processing = true
                this.document = this.removeNonNumeric(this.document)
                _sSunarp.searchsunarp(this.document, this.$fun.getUserID()).then(resp => {

                        if (resp.status == 200) {
                            this.save(1, resp.data == null ? "" : resp.data.SunName)
                            this.person.NtpName = resp.data.SunName
                            this.person.PrsDocumentNumber = resp.data.SunNumberDocument
                            this.processing = false
                            this.messageProcessing = ""
                            this.document = "";
                            this.clickBack();
                        }
                    },
                    (e) => {
                        console.log("data sunat", e.status)
                        this.save(0, e.status == null ? "" : resp.data.SunName)
                        this.person.NtpName = "No se encontraron datos"
                        this.person.PrsDocumentNumber = this.document
                        this.document = "";
                        this.processing = false
                        this.messageProcessing = ""
                        this.clickBack();
                    });
            }
        }, */

        /* btnSearch() {
            _sOC.listoc({
                DocCustomer: this.document
            }, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                    console.log("itemsoc", resp.data)
                    this.itemsOC = resp.data
                }
            })
        }, */

        rowClickOC: function (item, row) {
            this.selectedOC = [item];

            console.log("this.selectedOC ", this.selectedOC)

            /* console.log("selectedOC", this.selectedOC)

            this.filter.CodSales = this.selectedOC[0].CodSales */

        },

        //Cobranzas
        /* btnSearchGestion() {
            var obj = {
                QryParamsArray: "NumberDocument",
                QryParamsArrayValue: this.document,
                QryNameProcedure: "CobSearchCustomerGestion"
            }
            _sQryConfigurationService
                .querygeneral(obj, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {

                            if (resp.data.length > 0) {
                                resp.data.forEach(element => {
                                    element.CobDateRegister = element.CobDateRegister == null ? "" : this.$moment(element.CobDateRegister).format(this.$const.FormatDateTimeDB);
                                    element.CobDateCommitment = element.CobDateCommitment == null ? "" : this.$moment(element.CobDateCommitment).format(this.$const.FormatDateDB);
                                });
                            }

                            this.itemsCobranza = resp.data
                            console.log("this.itemsCobranza", this.itemsCobranza)
                        }
                    }
                );
        }, */

        //Listar Visitas
        listVisit() {
            var obj = {
                QryParamsArray: "NumberDocument",
                QryParamsArrayValue: this.person.PrsDocumentNumber !== "" ? this.person.PrsDocumentNumber : this.document, //this.DateBegin + "," + this.DateEnd + "," + this.number;
                QryNameProcedure: "MngCustomerVisit_L"
            }
            _sQryConfigurationService
                .querygeneral(obj, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.itemsVisita = resp.data
                            if (this.itemsVisita.length > 0) {
                                this.itemsVisita.forEach(element => {
                                    element.VstDate = element.SecCreate == null ? "" : this.$moment(element.SecCreate).format(this.$const.FormatDateDB);
                                    element.VstHour = element.SecCreate == null ? "" : this.$moment(element.SecCreate).format(this.$const.FormatTimeDB);
                                });
                            }

                        }
                    }
                );
        },

        rowClickVisita: function (item, row) {
            this.selectedVisita = [item];
        },

        /* viewComprobantes(){
            _sControl.getPDF({PrsDocumentNumber: this.person.PrsDocumentNumber}, this.$fun.getUserID()).then(resp => {
                console.log("resp", resp.data)
                this.$fun.downloadFile(resp.data, this.$const.TypeFile.PDF, "Boleta");
            })
        } */
    },

};
</script>

<style>
.inputControl input {
    font-size: 28px !important;
}

.background {
    background-repeat: no-repeat;
    /* Indicamos que no se repetira */
    background-size: cover !important;
    /* Encajamos la imagen al 100% del ancho */
    background-attachment: fixed !important;
    /* Establecemos una posicion fija para la imagen */
    /* background-image: linear-gradient(to top,
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0)),
        url("../../../assets/imp.jpg"); */
}
</style>
