<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>
    <v-card class="mt-2">

        <s-toolbar style="font-family: Calibri;" label="INMOBILIARIA MIRAFLORES PERÚ SAC" dark close @close="$emit('close')" save @save="save()">

        </s-toolbar>

        <v-divider></v-divider>

        <v-card outlined>
            <v-col>
                <v-row>
                    <v-card width="100%" outlined style="background:rgba(255,255,255,0.9); padding:0px;margin:0px;">
                        <v-card-title>
                            <v-row>
                                <v-col class='d-flex justify-center '>
                                    <h4 style="font-size: 12px !important; line-height: 1.1" class="s-color-title d-none d-sm-flex text-center">
                                        Datos del Visitante
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>
                    <v-col cols="12" lg="12" md="12">
                        <v-col class="s-color-title mt-10" style="" cols="12">

                            <h1 v-if="person.NtpName" style="font-size: 40px !important; line-height: 1.1" class="s-color-title d-none d-sm-flex text-center">
                                <v-row justify="center">
                                    {{
                        person.NtpName
                      }}</v-row>
                            </h1>
                            <h1 v-if="person.NtpName" style="font-size: 32px !important" class="d-flex d-sm-none s-color-title text-center">
                                {{
                      person.NtpName
                    }}
                            </h1>

                            <h1 v-else style="font-size: 40px !important" class="s-color-title text-center">
                                CONTROL DE VISITAS CLIENTE
                            </h1>

                            <h1 style="font-size: 32px !important" class="d-none d-sm-flex s-color-title text-center">
                                <v-col class="d-flex justify-center" style="padding:0px">{{
                      person.PrsDocumentNumber
                    }}</v-col>
                            </h1>
                            <h1 style="font-size: 24px !important" class="text-center d-sm-none s-color-title">
                                {{ person.PrsDocumentNumber }}
                            </h1>
                        </v-col>
                    </v-col>
                </v-row>
                <v-row>
                    <v-card width="100%" outlined style="background:rgba(255,255,255,0.9); padding:0px;margin:0px;">
                        <v-card-title>
                            <v-row>
                                <v-col class='d-flex justify-center '>
                                    <h4 style="font-size: 12px !important; line-height: 1.1" class="s-color-title d-none d-sm-flex text-center">
                                        Motivo de la visita
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>
                     <v-col cols="12" lg="12" md="12" class="pt-0 pb-2">
                        <s-select-definition autocomplete full clearable label="Motivo Visita" :def="1185" return-object add v-model="ObjTypeVisit"></s-select-definition>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" class="pt-0 pb-2">
                        <c-select-area  :disabled="disabled" full :typeArea="3" no-default clearable label="Área Visitar" v-model="item.AreID"></c-select-area>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" class="pt-0 pb-0">
                        <s-select-definition  :disabled="disabled2" autocomplete full clearable label="Responsable" :def="1186" add v-model="item.TypeResponsible"></s-select-definition>
                    </v-col>
                   
                    <v-col cols="12" class="pt-0 pb-2">
                        <s-textarea label="Descripción" v-model="item.VstDescription"></s-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-card>
</div>
</template>

<script>
/* import cSelectArea from '../../../components/Utils/cSelectArea.vue'; */
import _ControlAccessService from "@/services/Management/ControlAccess";

export default {
    components: {
        
    },
    props: {
        person: null
    },
    data() {
        return {
            messageProcessing: "",
            processing: false,
            item: {
                AreID: null,
                TypeResponsible: null,
                TypeVisit: null,
                VstDescription: null

            },
            ObjTypeVisit:null,
            disabled:false,
            disabled2:false
        };
    },

    watch:{
        ObjTypeVisit(){
            this.item.AreID = this.ObjTypeVisit !== null? parseInt(this.ObjTypeVisit.DedHelper.split(',')[0]) > 0 ? parseInt(this.ObjTypeVisit.DedHelper.split(',')[0]) : 0 : null
            this.item.TypeResponsible = this.ObjTypeVisit !== null? parseInt(this.ObjTypeVisit.DedHelper.split(',')[1]) > 0 ? parseInt(this.ObjTypeVisit.DedHelper.split(',')[1]) : 0 : null

            console.log(this.item,this.ObjTypeVisit)

            if(this.item.AreID > 0){
                this.disabled = true
            }else{
                this.disabled = false
            }

            if(this.item.TypeResponsible > 0){
                this.disabled2 = true
            }else{
                this.disabled2 = false
            }
        }
    },

    methods: {
        save() {

            if(this.ObjTypeVisit == null){
                this.$fun.alert("Seleccione Motivo de Visita", "warning")
                return
            }

            if(this.item.AreID == null || this.item.AreID <= 0){
                this.$fun.alert("Seleccione Área a Visitar", "warning")
                return
            }

            if(this.item.TypeResponsible == null || this.item.TypeResponsible <= 0){
                this.$fun.alert("Seleccione Responsable", "warning")
                return
            }

            

            this.item.TypeVisit = this.ObjTypeVisit !== null? parseInt(this.ObjTypeVisit.DedValue) : null
            
            this.item.NtpName = this.person.NtpName
            this.item.PrsDocumentNumber = this.person.PrsDocumentNumber
            this.item.SecStatus =1
            this.item.UsrCreateID = this.$fun.getUserID()

            console.log("save", this.item)
            this.$fun.alert("¿Seguro de registrar visita?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true
                    _ControlAccessService.saveVisit(this.item, this.$fun.getUserID()).then(resp => {
                            if (resp.status == 200) {
                                this.$fun.alert("Registrado correctamente", "success", false)
                                this.processing = false
                                this.$emit('close')
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            })

        }
    },
};
</script>
