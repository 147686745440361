import Service from "../Service";

const resource = "controlcustomer/";

export default {

    byDocument(parameters, requestID) {
        return Service.post(resource + "searchcustomer", parameters, {
            params: { requestID: requestID },
        });
    },

    getPDF(obj, requestID) {
        return Service.post(resource + "getpdf", obj, {
            params: { requestID: requestID },
        });
    },
};