//controlaccess
import Vue from "vue";
import Service from "../Service";

const resource = "accesshistory/";

export default {


    //VISITA
    saveVisit(obj, requestID) {
        return Service.post(resource + "saveVisit", obj, {
            params: { requestID: requestID },
        });
    },



    saveaccesscustomer(document, name, usrID, controlaccess) {
        return Service.post(resource + "saveaccesssutomer", {}, {
            params: {
                number: document,
                name: name,
                usrID: usrID,
                controlaccess: controlaccess
            },
        });
    },

    personaccess(document, usrID, controlAccess, registerHistory) {
        return Vue.prototype.$http.post(resource + "controlaccess", {}, {
            params: {
                number: document,
                usrID: usrID,
                controlaccess: controlAccess,
                registerHistory: registerHistory == undefined ? 0 : registerHistory
            },
        });
    },
    historyaccess(obj) {
        return Vue.prototype.$http.post(resource + "accesscontrolreport", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
}